.profile-wrapper {
  display: flex;
  justify-content: space-evenly;
  padding-top: 2rem;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  .profile-top-component {
    display: flex;
    gap: 2rem;
    align-items: center;
  }

  .profile-middle-buttons-component {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 0.5rem;

    .nav-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: none;
      background: none;
      cursor: pointer;
      font-size: 14px;
      color: white;
      transition: 0.3s;
    }

    .icon-circle {
      width: 50px;
      height: 50px;
      background-color: lightgrey; /* White background for the circle */
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
      transition: 0.3s;
    }

    .nav-button.active .icon-circle {
      background-color: darkgreen; /* Highlight active button */
      color: white;
    }

    .nav-button span {
      font-size: 14px;
      font-weight: bold;
      color: darkgreen; /* Text color */
    }

    .nav-button:hover {
      color: lightgreen; /* Hover effect */
    }
  }
}
