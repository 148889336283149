.footer-wrapper {
  display: flex;
  padding: 15px 25px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  .first-row-component {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .links-container {
      display: flex;
      flex-direction: column;
    }

    a {
      color: darkgray;
    }

    a:hover {
      color: darkgreen;
    }

    .description-text {
      font-size: 1.2rem;
    }
  }

  .logos-wrapper {
    display: flex;
    justify-content: center;
    width: 33%;
    gap: 1rem;
    align-items: center;
  }

  .bottom-left {
    font-size: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .footer-wrapper {
    display: flex;
    padding: 15px 25px;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    .first-row-component {
      width: 100%;
    }
  }
}
