.imageBookmarkRating {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  padding-top: 2rem !important;
  gap: 1rem;

  .rating-and-CTA {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.restOfContent {
  display: flex;
  // flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 1rem !important;
  .aboveMap {
    width: 100%;
    padding-left: 1rem;

    .title {
      font-size: 2.5rem;
      color: #1f3933;
      font-weight: 600;
    }

    .ratingWrapper {
      display: flex;
      padding: 1rem 0rem;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      gap: 1rem;

      .ratingsAverage {
        color: black;
        font-size: 20px;
      }

      .reviewsNo {
        font-size: 17px;
        color: darkgrey;
        font-weight: 400;
      }
    }
    .propWrapper {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 0.3rem 0rem;
      flex-wrap: wrap;

      .propName {
        display: flex;
        color: #1f3933;
        font-size: 1.5rem;
        font-weight: 600;
        align-items: center;
      }

      .propValue {
        color: #006241;
        font-size: 1.3rem;
      }

      .flavorNotes {
        color: #006241;
        font-size: 1.3rem;
        display: flex;
        gap: 1rem;
      }
    }

    .clickable {
      cursor: pointer;
    }

    .traits {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem 1.5rem;
      align-items: flex-start;
      margin: 1rem 0.5rem 1rem -0.5rem;
      border-radius: 20px;
      box-shadow: 10px 20px 55px 0 rgba(92, 92, 92, 0.3);
      max-width: 400px;

      .propWrapper {
        display: flex;
        width: 100%;
        align-items: flex-end;
        gap: 1rem;
        padding: 0.3rem 0rem;
        justify-content: space-between;
        .propName {
          color: #1f3933;
          font-size: 1.5rem;
          font-weight: 600;
        }

        .propValue {
          color: darkgray;
          font-size: 1rem;
          display: flex;
          align-items: center;
          gap: 0.7rem;
        }
      }
    }
  }
}
