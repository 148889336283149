.video-parent-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    position: absolute;
    color: white;
    padding: 1rem;
    margin-bottom: 7rem;
    max-width: 640px;
    // font-size: 75px;
    font-size: 61px;
    font-style: normal;
    font-weight: 900;
    line-height: 80px;
    letter-spacing: -2.88px;
    text-transform: uppercase;
  }
  .video {
    height: 30rem;
    width: 100vw;
    object-fit: cover;
    margin-top: -10px;
    margin-left: -10px;
    margin-right: -10px;
  }

  .hero-button {
    position: absolute;
    margin-top: 15rem;
    border: 3px solid white;
    color: white;
    padding: 12px;
    font-size: 1.4rem;
  }
}
