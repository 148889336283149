.inputAndButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  gap: 2rem;
}

.text {
  padding: 0rem 1rem 0rem;
  div {
    // color: #1f3933;
    color: #1f3933;
    font-size: 2rem;
    font-weight: 600;
  }
}

.loginDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
