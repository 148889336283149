.SearchWrapper {
  align-content: center;
  // margin-bottom: 11px;
  margin-left: 1rem;
  // min-width: 140px;
}

.resultWrapper {
  position: absolute;
  background: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid darkgray;
  border-radius: 6px;
  margin-top: 10px;
  margin-left: -15px;
  max-width: 75vw;

  // loading text
  .loadingText {
    color: rgb(0, 98, 65);
    font-weight: 500;
    margin: 0px 50px;
    text-decoration: underline;
  }

  .SearchResultRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-bottom: 6px;
    border: 1px solid lightgray;

    .rightSide {
      margin: 10px;
      p {
        margin-bottom: 0;
      }
      .title {
        font-weight: 500;
      }
      .subtitle {
      }
    }
  }

  .view-more {
    color: rgb(0, 98, 65);
    padding: 0.5rem;
    text-decoration: underline;
    cursor: pointer;
  }
}
