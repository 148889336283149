.resetPassWrapper {
  display: flex;
  flex-direction: column;

  .text {
    padding: 3rem 0rem;
  }

  .inputAndButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    gap: 3rem;
    // margin-top: 7rem;
  }
}
