.written-reviews-wrapper {
  margin: 2rem 0rem;
  padding: 3rem 0rem;
  border-top: 1px solid;
  border-bottom: 1px solid;

  .boxStyling {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    .add-rating-CTA {
      text-align: center;
      color: #006241;
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }
  }
}
