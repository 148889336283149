.top-items-wrapper {
  // margin-left: 1rem;
  padding: 1rem 0rem;

  .section-title {
    padding: 1rem 0rem;
    margin-left: 1rem;
    color: rgb(0, 98, 65);
    margin: 0 0 32px;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px;
    letter-spacing: -1.44px;
    text-transform: uppercase;
  }
}
.topBeansContainer {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin-right: -10px;
}

.skeletonItem {
  margin-right: 5px;
  margin: 1rem;
  .skeleton-component {
    border-radius: 12px;
  }
}

.parentWrapper {
  max-width: 219px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  // margin: 0px 35px 35px 8px;
  margin: 22px 14px;
  justify-content: space-between;
  padding: 1rem;
  box-shadow: 10px 20px 55px 0 rgba(92, 92, 92, 0.4);
  border-radius: 20px;
  .image {
    // margin: 0px 5px;
    // width: 11rem;
    // object-fit: contain;
    // height: 11rem;
    // // flex-grow: 1;
    margin: 0px 5px;
    width: 100%;
    object-fit: contain;
    height: 15rem;
  }

  .underPic {
    // flex-grow: 2;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-evenly;
    margin-left: 10px;
    .title {
      font-size: 1.4rem;
      font-weight: 500;
      color: rgb(0, 98, 65);
    }

    .brand {
      font-size: 1.3rem;
      font-weight: 400;
      color: black;
    }

    .ratingsAndNo {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      position: relative;
      left: -13px;
      margin-bottom: 0.5rem;

      .ratingsAverage {
        color: black;
        font-size: 20px;
      }

      .reviewsNo {
        font-size: 17px;
        color: darkgrey;
        font-weight: 400;
      }
    }

    .originWrapper {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      .origin {
        font-size: 1.2rem;
        font-weight: 400;
      }
    }
  }
}

.topBeansContainer::-webkit-scrollbar {
  height: 8px;
}

.topBeansContainer::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 10px;
}

// .topBeansContainer::-webkit-scrollbar-track {
//   background-color: lightgray;
//   border-radius: 10px;
// }
