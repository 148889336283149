// .containerStyle {
//   display: flex;
//   justify-content: flex-start;
//   //     justify-content: space-around;
//   flex-direction: row;
//   align-items: center;
// }

.searchAndLinksContainer {
  display: flex;
  justify-content: space-evenly;
  gap: 2vw;
  // width: 40vw;

  // display: flex;
  // // justify-content: space-evenly;
  // align-items: center;
  // width: 100%;
  // /* flex-direction: row; */
  // justify-content: space-between;
  // // gap: 2vw;
  // // width: 40vw;

  .link-text {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 576px) {
  .logoSearch {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    // align-items: center;
    align-items: flex-end;
    margin-bottom: 11px;
  }
}

@media screen and (min-width: 577px) and (max-width: 991px) {
  .logoSearch {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 11px;
  }
}

@media screen and (min-width: 992px) {
  .logoSearch {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 11px;
  }

  .containerStyle {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
  }
}
