.article-content {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  img {
    max-width: 250px;
    height: auto;
  }
}
