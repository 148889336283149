.article-parent {
  .article-wrapper {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    .upper-part {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0rem 1rem;
      gap: 1rem;
      .left {
        color: #1f3933;

        .title {
          display: flex;
          gap: 10px;
          align-items: baseline;
        }
      }
      .article-image {
      }
    }

    .key-facts {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;
      align-items: flex-start;
      margin: 1rem;
      border-radius: 20px;
      box-shadow: 0px -20px 55px 0 rgba(92, 92, 92, 0.3);
      gap: 1rem;

      .facts-row {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        flex-wrap: wrap;

        .prop {
          color: #1f3933;
          font-size: 1.5rem;
          font-weight: 600;
        }

        .regular-value {
          font-size: 1.5rem;
          font-weight: 100;
          //   color: darkgreen;
          color: #006241;
        }

        .big-value {
          font-size: 2rem;
          font-weight: 500;
          //   color: darkgreen;
          color: #006241;
        }
      }
    }

    .description {
      padding: 0rem 1rem;
      color: #1f3933;
    }
  }
}
