.parent-wrapper {
  padding: 1rem;

  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}
