.guidesParent {
  .skeletonItemGuideHome {
    display: flex;
    justify-items: center;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
  }
  .guidesTitle {
    margin-bottom: 2rem;
  }
  .guideCardCompWrapper {
    display: flex;
    justify-items: center;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 3rem;
  }
}
