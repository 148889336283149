.pageWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15vh;

  .inputAndButton {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    gap: 2rem;
    padding: 2rem 1rem 2rem 1rem;
    align-items: center;
    margin: 1rem 0rem;
    border-radius: 20px;
    box-shadow: 10px 20px 55px 0 rgba(92, 92, 92, 0.3);
  }

  .text {
    padding: 0rem 1rem 0rem;
    // display: flex;
    // flex-wrap: nowrap;
    // gap: 1rem;
    // align-items: center;

    div {
      color: #1f3933;
    }
  }

  .loginDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    div {
      color: #1f3933;
    }

    .link {
      color: #006241;
      text-decoration: underline;
      cursor: grab;
    }
  }
}
