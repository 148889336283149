.explore-wrapper {
  .top-row {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // padding: 0rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    gap: 35px;
  }
}
