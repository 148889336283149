.beanSelectorWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 1rem 2rem 1rem;
  align-items: center;
  max-width: 700px;
  margin: 2rem auto;
  border-radius: 20px;
  box-shadow: 10px 20px 55px 0 rgba(92, 92, 92, 0.3);

  .selector-title {
    margin: 0 0 32px;
    font-size: 41px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0.56px;
    text-transform: uppercase;
  }

  .box {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    padding: 2rem 0rem;

    .roastLevel {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      margin-right: 13px;
      h2 {
        margin-right: written-reviews-wrappervw;
        font-size: 1.6rem;
        font-weight: 400;
      }
    }

    .type {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      gap: 2vw;

      h2 {
        margin-right: 5vw;
        font-size: 1.6rem;
        font-weight: 400;
      }

      .typeOptions {
        display: flex;
        flex-direction: row;
        // gap: 1vw;
      }
    }

    .findButton {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .button {
        background-color: #006241;
      }
    }
  }
}

// .MuiSlider-thumb {
//   color: #006241;
// }
// .MuiSlider-rail {
//   color: lightgreen;
// }
// .MuiSlider-track {
//   color: #006241;
// }
