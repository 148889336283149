.imageBookmarkRating {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  padding-top: 2rem !important;
  gap: 2rem;
}
.restOfContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 1rem !important;

  .ck-editor__editable {
    min-height: 150px;
    color: black;
  }

  .aboveMapCreateBean {
    display: flex;
    flex-direction: column;
    width: 98%;
    align-items: flex-start;
    margin-left: 1rem;

    .titleTextFieldWrapper {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: baseline;
      width: 90%;
      max-width: 400px;
      margin-bottom: 0.5rem;
      .title {
        font-size: 1.5rem;
        color: #1f3933;
        font-weight: 600;
      }

      .brand {
        font-size: 1.5rem;
        color: #006241;
      }

      .subtitle {
        font-size: 1.3rem;
        color: #1f3933;
      }
    }

    .dropdownTitle {
      margin-left: 2rem;
      color: #006241;
    }

    .advancedWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;

      .subtitleTextFieldWrapper {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: baseline;
        .title {
          font-size: 2.5rem;
          color: #1f3933;
          font-weight: 600;
        }

        .subtitle {
          color: #1f3933;
          font-size: 1.5rem;
          font-weight: 600;
        }
      }

      @media screen and (max-width: 576px) {
        .roast-slider {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          align-items: baseline;
          // background-color: pink;
          width: 100%;

          .subtitle {
            color: #1f3933;
            font-size: 1.5rem;
            font-weight: 600;
          }

          .slider {
            align-self: center;
          }
        }
      }

      @media screen and (min-width: 577px) {
        .roast-slider {
          display: flex;
          flex-direction: row;
          gap: 2rem;
          align-items: baseline;
          // background-color: lightblue;

          .subtitle {
            color: #1f3933;
            font-size: 1.5rem;
            font-weight: 600;
          }
          .slider {
          }
        }
      }
    }

    .ratingWrapper {
      display: flex;
      padding: 1rem 0rem;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      gap: 1rem;
    }
    .propWrapper {
      display: flex;
      align-items: baseline;
      gap: 1rem;
      padding: 0.3rem 0rem;
      .propName {
        color: #1f3933;
        font-size: 1.5rem;
        font-weight: 600;
      }

      .propValue {
        color: #006241;
        font-size: 1.3rem;
      }
    }

    .traits {
      // margin: 1rem 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 3rem 1rem 2rem 1rem;
      align-items: flex-start;
      margin: 1rem 0.5rem 1rem -0.5rem;
      border-radius: 20px;
      box-shadow: 10px 20px 55px 0 rgba(92, 92, 92, 0.3);

      .propWrapper {
        display: flex;
        align-items: flex-end;
        gap: 1rem;
        padding: 0.3rem 0rem;
        .propName {
          color: #1f3933;
          font-size: 1.5rem;
          font-weight: 600;
        }

        .propValue {
          color: darkgray;
          font-size: 1.3rem;
          display: flex;
          align-items: center;
          gap: 0.7rem;
        }
      }
    }
  }
}

// @media screen and (max-width: 576px) {
//   .roast-slider {
//     display: flex;
//     flex-direction: column;
//     gap: 1rem;
//     align-items: baseline;
//   }
// }
